// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10945(2eb911ca14)-C03/12/2024-08:01:58-B03/12/2024-08:06:01' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10945(2eb911ca14)-C03/12/2024-08:01:58-B03/12/2024-08:06:01",
  branch: "master",
  latestTag: "6.1",
  revCount: "10945",
  shortHash: "2eb911ca14",
  longHash: "2eb911ca14d8baedfbd7faab031c7d42f2577752",
  dateCommit: "03/12/2024-08:01:58",
  dateBuild: "03/12/2024-08:06:01",
  buildType: "Ansible",
  } ;
